<template>
  <div>
    <v-pagination v-model="page" :length="amountOfQuestions"></v-pagination>
    <v-divider class="mt-2 mb-2"></v-divider>
    <v-container>
      <v-card class="pa-2">
        <v-row
          ><v-col cols="3"></v-col>
          <v-col cols="6">
            <v-card
              ><v-card-text>
                <v-container>
                  <v-row v-if="questions && questions[page - 1]">
                    <v-col cols="9">
                      <v-text-field
                        label="Question"
                        class="title"
                        v-if="questions && questions.length > 0"
                        v-model="questions[page - 1].description"
                        :disabled="disabled"
                        :rules="[v => !!v || 'Required']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <SurveyQuestionTypeField
                        label="Question Type"
                        :disabled="disabled"
                        v-model="questions[page - 1].surveyQuestionType"
                      ></SurveyQuestionTypeField>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="
                      questions &&
                        questions[page - 1] &&
                        questions[page - 1].surveyQuestionType &&
                        (questions[page - 1].surveyQuestionType.name == 'MULTIPLE_CHOICE' ||
                          questions[page - 1].surveyQuestionType.name == 'CHECK_BOXES')
                    "
                  >
                    <v-col>
                      <v-text-field
                        v-for="(option, index) in questions[page - 1].options"
                        v-model="questions[page - 1].options[index].description"
                        :key="index"
                        :label="'Option ' + (index + 1)"
                        :ref="'option' + index"
                        @blur="
                          if (amountOfOptions > 1 && !addingNewOption) {
                            removeEmptyOption();
                          }
                        "
                        @keydown.tab="
                          addingNewOption = true;
                          if (index == questions[page - 1].options.length - 1) {
                            addNewOption();
                          }
                        "
                        :disabled="disabled"
                        :rules="[v => !!v || 'Required']"
                      >
                        <template v-slot:append>
                          <v-icon
                            :disabled="disabled"
                            color="red"
                            @click="
                              if (amountOfOptions > 1) {
                                deleteOption(index);
                              }
                            "
                            >mdi-close</v-icon
                          >
                        </template>
                      </v-text-field>
                      <v-text-field
                        class="newOptionTextField"
                        placeholder="New option"
                        style="cursor:text"
                        readonly
                        flat
                        :disabled="disabled"
                        @click="addNewOption()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-switch
                  label="Required"
                  v-if="questions[page - 1]"
                  v-model="questions[page - 1].required"
                  :disabled="disabled"
                  class="mr-2 mt-n1"
                  dense
                  :hide-details="true"
                ></v-switch>

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" small color="primary" :disabled="page == 1 || disabled" @click="moveQuestionLeft()"
                      ><v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  Move left
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-2"
                      v-on="on"
                      small
                      color="primary"
                      @click="moveQuestionRight()"
                      :disabled="page == questions.length || disabled"
                      ><v-icon>mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                  Move right
                </v-tooltip>
                <v-divider vertical class="ml-2 mr-2"></v-divider>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn small v-on="on" color="success" @click="addNewQuestion" :disabled="disabled">
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  Add new Question
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ml-2"
                      v-on="on"
                      small
                      color="warning"
                      @click="showDeleteQuestionDialog = true"
                      :disabled="amountOfQuestions == 1 || disabled"
                      ><v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete Question
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row> </v-row>
      </v-card>
      <v-dialog v-model="showDeleteQuestionDialog" max-width="500">
        <v-card>
          <v-card-title>
            Delete Question
          </v-card-title>
          <v-card-text>
            Are you sure you want to delete this question?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="showDeleteQuestionDialog = false">Cancel</v-btn>
            <v-btn
              class="primary"
              @click="
                deleteQuestion();
                showDeleteQuestionDialog = false;
              "
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import SurveyQuestionTypeField from "../fields/SurveyQuestionTypeField.vue";
import ApiService from "../../services/api.service";
import { mapGetters } from "vuex";

export default {
  components: { SurveyQuestionTypeField },
  name: "SurveyQuestions",
  props: {
    value: Object,
    id: {
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showParticipantGroups: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    surveyQuestionTypes: [],
    errors: {},
    questions: {},
    page: 1,
    selectedQuestion: {},
    showDeleteQuestionDialog: false,
    addingNewOption: false,
    defaultQuestion: {
      id: 0,
      description: "",
      options: [{ id: 0, description: "" }],
      surveyQuestionType: {
        name: "MULTIPLE_CHOICE",
        description: "Multiple Choice"
      },
      required: true
    },
    deletedQuestions: [],
    deletedOptions: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      return ApiService.get("/api/surveyQuestions/bySurveyId/" + this.$route.params.id)
        .then(({ data }) => {
          this.questions = data.sort((a, b) => a.rank - b.rank);
          this.tableDataHeaders = [
            {
              value: "participantKey",
              text: "Participant Key",
              sortable: true
            },
            { value: "participantFirstName", text: "First Name", sortable: true },
            { value: "participantLastName", text: "Last Name", sortable: true }
          ];
          for (let question of this.questions) {
            this.tableDataHeaders.push({
              value: question.id.toString(),
              text: question.description,
              sortable: false
            });
          }
        })
        .then(() => {
          if (!this.questions || this.questions.length == 0) {
            this.questions = [JSON.parse(JSON.stringify(this.defaultQuestion))];
          } else {
            this.questions.forEach(question => (question.options = question.options.sort((a, b) => a.rank - b.rank)));
          }
        });
    },
    onSubmit() {
      return this.saveQuestions().then(() => {
        return this.submitDeleteOptions().then(() => {
          return this.submitDeleteQuestions().then(() => {
            return this.fetchData().then(() => {
              this.deletedQuestions = [];
              this.deletedOptions = [];
              this.$emit("input", this.questions);
              this.$emit("onLoad");
            });
          });
        });
      });
    },
    saveQuestions() {
      let promises = [];
      for (let [index, question] of this.questions.entries()) {
        if (question.id && question.id != 0) {
          promises.push(
            ApiService.patch("/api/surveyQuestions/" + question.id, {
              survey: ApiService.getSelfUrl("surveys", { id: this.id }),
              description: question.description,
              surveyQuestionType: question.surveyQuestionType,
              rank: index,
              required: question.required,
              options: question.options
                .filter(option => option.id && option.id != 0)
                .map(option => this.$api.getSelfUrl("surveyQuestionOptions", option))
            }).then(({ data }) => {
              let promises = [];
              for (let [index, option] of question.options.entries()) {
                if (option.id && option.id != 0) {
                  promises.push(
                    ApiService.patch("/api/surveyQuestionOptions/" + option.id, {
                      description: option.description,
                      surveyQuestion: ApiService.getSelfUrl("surveyQuestions", data),
                      rank: index
                    })
                  );
                } else {
                  promises.push(
                    ApiService.post("/api/surveyQuestionOptions", {
                      description: option.description,
                      surveyQuestion: ApiService.getSelfUrl("surveyQuestions", data),
                      rank: index
                    })
                  );
                }
              }
              return Promise.all(promises);
            })
          );
        } else {
          promises.push(
            ApiService.post("/api/surveyQuestions/", {
              survey: ApiService.getSelfUrl("surveys", { id: this.id }),
              description: question.description,
              surveyQuestionType: question.surveyQuestionType,
              rank: index,
              required: question.required
            }).then(({ data }) => {
              let promises = [];
              for (let [index, option] of question.options.entries()) {
                promises.push(
                  ApiService.post("/api/surveyQuestionOptions", {
                    description: option.description,
                    surveyQuestion: ApiService.getSelfUrl("surveyQuestions", data),
                    rank: index
                  })
                );
              }
              return Promise.all(promises);
            })
          );
        }
      }
      return Promise.all(promises);
    },
    addNewOption() {
      if (this.questions[this.page - 1].options[this.questions[this.page - 1].options.length - 1].description) {
        this.questions[this.page - 1].options.push({ id: 0, description: null });
        setTimeout(() => {
          this.$nextTick(() => {
            let ref = "option" + (this.questions[this.page - 1].options.length - 1);
            this.$refs[ref][0].focus();
            this.addingNewOption = false;
          });
        }, 100);
      }
    },
    addNewQuestion() {
      let currentPosition = this.page - 1;
      let positionToInsertInto = currentPosition + 1;
      this.questions.splice(positionToInsertInto, 0, JSON.parse(JSON.stringify(this.defaultQuestion)));
      this.page = positionToInsertInto + 1;
    },

    deleteQuestion() {
      if (
        this.questions &&
        this.questions[this.page - 1] &&
        this.questions[this.page - 1].id &&
        this.questions[this.page - 1].id != 0
      ) {
        this.deleteAllOptionsForQuestion(this.questions[this.page - 1]);
        this.deletedQuestions.push(this.questions[this.page - 1]);
      }
      this.questions.splice(this.page - 1, 1);
      if (this.page > this.questions.length) {
        this.page--;
      }
    },
    submitDeleteQuestions() {
      return this.deletedQuestions.reduce((prev, next) => {
        return prev.then(() => {
          return this.$api.delete("/api/surveyQuestions/" + next.id);
        });
      }, Promise.resolve());
    },
    submitDeleteOptions() {
      return this.deletedOptions.reduce((prev, next) => {
        return prev.then(() => {
          return this.$api.delete("/api/surveyQuestionOptions/" + next.id);
        });
      }, Promise.resolve());
    },
    deleteOption(optionIndex) {
      if (this.questions[this.page - 1].options[optionIndex]) {
        this.deletedOptions.push(this.questions[this.page - 1].options[optionIndex]);
        this.questions[this.page - 1].options.splice(optionIndex, 1);
      }
    },
    deleteAllOptionsForQuestion(question) {
      let amountOfOptions = question.options.length;
      for (let i = 0; i < amountOfOptions; i++) {
        this.deleteOption(0);
      }
    },
    removeEmptyOption() {
      let optionsCount = this.questions[this.page - 1].options.length;
      let lastElement = this.questions[this.page - 1].options[optionsCount - 1];
      if (lastElement.description == "" || !lastElement.description) {
        this.questions[this.page - 1].options.splice(optionsCount - 1, 1);
      }
    },
    moveQuestionLeft() {
      let currentPosition = this.page - 1;
      let aux = Object.assign({}, this.questions[currentPosition - 1]);
      this.questions[currentPosition - 1] = this.questions[currentPosition];
      this.questions[currentPosition] = aux;
      this.page--;
    },
    moveQuestionRight() {
      let currentPosition = this.page - 1;
      let aux = Object.assign({}, this.questions[currentPosition + 1]);
      this.questions[currentPosition + 1] = this.questions[currentPosition];
      this.questions[currentPosition] = aux;
      this.page++;
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetchData().then(() => {
        this.$emit("input", this.audience);
        this.$emit("onLoad");
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isNew() {
      return this.id == "0" || this.id == 0;
    },
    amountOfQuestions() {
      return this.questions.length;
    },
    amountOfOptions() {
      return this.questions[this.page - 1].options.length;
    }
  }
};
</script>
