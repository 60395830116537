<template>
  <v-data-table :items="tableData" :headers="tableDataHeaders">
    <template v-for="header in tableDataHeaders" v-slot:[`item.${header.value}`]="{ item }">
      {{ getTextForItemAndHeader(item, header) }}
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "../../services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "SurveyAnswers",
  props: {
    value: Object,
    id: {
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fileNameStart: {
      type: String,
      default: "Survey"
    }
  },
  data: () => ({
    surveyQuestionTypes: [],
    errors: {},
    details: {},
    isGeneratingCSVReport: false,
    tableData: [],
    allData: [],
    answers: [],
    questions: [],
    tableDataHeaders: [],
    exportingHeaders: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      return this.$api
        .get("/api/surveyQuestions/bySurveyId/" + this.$route.params.id)
        .then(({ data }) => {
          this.questions = data.sort((a, b) => a.rank - b.rank);
          this.tableDataHeaders = [
            {
              value: "participant.participantKey",
              text: "Participant Key",
              sortable: true
            },
            { value: "participant.user.firstName", text: "First Name", sortable: true },
            { value: "participant.user.lastName", text: "Last Name", sortable: true }
          ];
          this.exportingHeaders = Object.assign([], this.tableDataHeaders);
          this.exportingHeaders.push(
            {
              value: "organizationKey",
              text: "Organization Key",
              sortable: false,
              render: item =>
                item.participant && item.participant.organization && item.participant.organization.organizationKey
                  ? item.participant.organization.organizationKey
                  : ""
            },

            {
              value: "organizationName",
              text: "Organization",
              sortable: false,
              render: item =>
                item.participant && item.participant.organization && item.participant.organization.name
                  ? item.participant.organization.name
                  : ""
            },
            {
              value: "participantType",
              text: "Participant Type",
              sortable: false,
              render: item =>
                item.participant && item.participant.participantType && item.participant.participantType.name
                  ? item.participant.participantType.name
                  : ""
            },
            {
              value: "participantEmail1",
              text: "Participant Email 1",
              sortable: false,
              render: item => (item && item.participant && item.participant.email1 ? item.participant.email1 : "")
            },
            {
              value: "participantPhoneNumber1",
              text: "Participant Phone Number 1",
              sortable: false,
              render: item =>
                item && item.participant && item.participant.phoneNumber1 ? item.participant.phoneNumber1 : ""
            },
            {
              value: "participantEnrollmentDate",
              text: "Participant Enrollment Date",
              sortable: false,
              render: item =>
                item && item.participant && item.participant.enrolledDate
                  ? this.$options.filters.formatDateClient(
                      item.participant.enrolledDate,
                      "MM/DD/YYYY",
                      this.selectedClient
                    )
                  : ""
            },
            {
              value: "participantLastSuccessfulLoginDate",
              text: "Participant Last Successful Login Date",
              sortable: false,
              render: item =>
                item && item.participant && item.participant.user && item.participant.user.lastSuccessfulLoginDate
                  ? this.$options.filters.formatDateClient(
                      item.participant.user.lastSuccessfulLoginDate,
                      "MM/DD/YYYY",
                      this.selectedClient
                    )
                  : ""
            }
          );
          this.tableDataHeaders.push({
            value: "organizationName",
            text: "Organization",
            sortable: false,
            render: item => (item.participant.organization ? item.participant.organization.name : "")
          });

          for (let question of this.questions) {
            this.tableDataHeaders.push({
              value: question.id.toString(),
              text: question.description,
              sortable: false
            });
            this.exportingHeaders.push({
              value: question.id.toString(),
              text: question.description,
              sortable: false
            });
          }
        })
        .then(() => {
          return ApiService.get("/api/surveys/answersBySurvey/" + this.id).then(({ data }) => {
            this.answers = data;
            this.tableData = [];
            this.answers.forEach(answer => {
              let newElement = Object.assign({}, answer);
              answer.surveyQuestionAnswers.forEach(answer2 => {
                answer2.questionId = answer2.surveyQuestion.id;
              });
              Object.values(this.groupBy(answer.surveyQuestionAnswers, "questionId")).forEach(elem => {
                newElement[elem[0].questionId.toString()] = elem.map(elem2 => elem2.answer).join(", ");
              });
              this.tableData.push(newElement);
            });
          });
        });
    },
    onSubmit() {
      return this.saveDetails().then(() => {
        return this.fetchData().then(() => {
          this.$emit("input", this.details);
          this.$emit("onLoad");
        });
      });
    },
    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    async generateCSVReport() {
      this.$emit("generatingCSV");
      let csvContent = "data:text/csv;charset=utf-8,";
      let headersRow = "";
      let delimiter = ",";

      for (let header of this.exportingHeaders) {
        headersRow += '"' + (header.text != undefined ? header.text : "") + '"' + delimiter;
      }

      csvContent += headersRow + "\r\n";
      await this.fetchAllData();
      for (let item of this.allData) {
        let row = "";
        for (let header of this.exportingHeaders) {
          let value = this.getTextForItemAndHeader(item, header);
          if (typeof value == "string") {
            value = value.replace('"', "'");
            value = value.trim();
          }
          row += '"' + value + '"' + delimiter;
        }
        csvContent += row + "\r\n";
      }

      var encodedUri = encodeURI(csvContent);
      encodedUri = encodedUri.replace(/#/g, "%23");
      var link = document.createElement("a");

      link.setAttribute("href", encodedUri);
      link.setAttribute("download", this.fileNameStart + "_answers_report.csv");
      document.body.appendChild(link);

      link.click();
      this.$emit("finishedGeneratingCSV");
    },
    async fetchAllData() {
      this.allData = [];
      let remainingElements = true;
      let page = 0;
      while (remainingElements) {
        await ApiService.get("/api/surveys/answersBySurvey/" + this.id + "?page=" + page + "&size=25").then(
          ({ data }) => {
            if (data.length == 0) {
              remainingElements = false;
            } else {
              let answers = data;

              answers.forEach(answer => {
                let newElement = Object.assign({}, answer);

                answer.surveyQuestionAnswers.forEach(answer2 => {
                  answer2.questionId = answer2.surveyQuestion.id;
                });
                Object.values(this.groupBy(answer.surveyQuestionAnswers, "questionId")).forEach(elem => {
                  newElement[elem[0].questionId.toString()] = elem.map(elem2 => elem2.answer).join(", ");
                });
                this.allData.push(newElement);
              });
              page++;
            }
          }
        );
      }
    },
    resolve(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    getTextForItemAndHeader(item, header) {
      return header.render != null
        ? header.render(item)
        : this.resolve(header.value, item) != null
        ? this.resolve(header.value, item)
        : "";
    }
  },
  mounted() {
    if (!this.isNew) {
      this.fetchData().then(() => {
        this.$emit("input", this.details);
        this.$emit("onLoad");
      });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"]),
    isNew() {
      return this.id == "0" || this.id == 0;
    }
  }
};
</script>
